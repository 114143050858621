
<template>
  <div class="main-content">


    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <b-container>
      <b-card  title="Add outlet" class="card-icon mb-30 p-16">
        <b-row>
          <b-col lg="12" md="12" sm="12">
            <form action="#" class="form-horizontal "  method="post" v-on:submit.prevent="validateBeforeSubmitNewOutlet">
              <input type="hidden" name="_token" />
              <div class="form-body">

                <app-timeline>
                  <app-timeline-item variant="primary">
                    <div  class="mb-4 bg-primary p-1" >
                      <h5 class="text-white">Primary Details</h5>
                    </div>
                    <div>
                      <div class="row" >
                        <div class="col-md-4">
                          <div class="form-group ">
                            <label class="control-label "><strong>Name<sup class="help text text-danger">*</sup>:</strong></label> <br/>
                            <div >
                              <input name="name" class="form-control" required type="text"  v-model="outlet.name" />
                              <!--<span v-show="errors.has('name')" class="help text text-danger">{{ errors.first('name') }}</span>-->
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group ">
                            <label class="control-label "><strong>Phone number<sup class="help text text-danger">*</sup>:</strong></label> <br/>
                            <div >
                              <input name="phone number" class="form-control" type="text" required  v-model="outlet.phoneNumber" placeholder="07xxxxxxxx" />
                              <!--<span v-show="errors.has('phone number')" class="help text text-danger">{{ errors.first('phone number') }}</span>-->
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group ">
                            <label class="control-label "><strong>Floor level<sup class="help text text-danger">*</sup>:</strong></label> <br/>
                            <div >
                              <select required class="form-control" v-model="outlet.floorLevel" name="floor level">
                                <option :value="floor_level" v-for="floor_level in floor_levels"> {{ floor_level }}</option>
                              </select>
                              <!--<span v-show="errors.has('floor level')" class="help text text-danger">{{ errors.first('floor level') }}</span>-->

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row" >

                        <div class="col-md-4">
                          <div class="form-group ">
                            <label class="control-label "><strong>Category<sup class="help text text-danger">*</sup>:</strong></label> <br/>
                            <div >
                              <select  required class="form-control" v-model="outlet.category" name="category">
                                <option :value="category.id" v-for="category in categories"> {{ category.name }}</option>
                              </select>
                              <!--<span v-show="errors.has('category')" class="help text text-danger">{{ errors.first('category') }}</span>-->
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group ">
                            <label class="control-label "><strong>Shop No:</strong></label> <br/>
                            <div >
                              <input class="form-control" type="text"  v-model="outlet.shopNo"   />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group ">
                            <label class="control-label "><strong>Website URL:</strong></label> <br/>
                            <div >
                              <input class="form-control" type="text"  v-model="outlet.website" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row" >
                        <div class="col-md-4">
                          <div class="form-group ">
                            <label class="control-label "><strong>Facebook:</strong></label> <br/>
                            <div >
                              <input class="form-control" type="text"  v-model="outlet.facebook" />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group ">
                            <label class="control-label "><strong>Twitter:</strong></label> <br/>
                            <div >
                              <input class="form-control" type="text"  v-model="outlet.twitter" />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group ">
                            <label class="control-label "><strong>Instagram:</strong></label> <br/>
                            <div >
                              <input class="form-control" type="text"  v-model="outlet.instagram" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row" >

                        <div class="col-md-4">
                          <div class="form-group ">
                            <label class="control-label "><strong>Youtube:</strong></label> <br/>
                            <div >
                              <input class="form-control" type="text"  v-model="outlet.youtube" />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group ">
                            <label class="control-label "><strong>Terminal Serial No.:</strong></label> <br/>
                            <div >
                              <input class="form-control" type="text"  v-model="outlet.terminalSerialNo" />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group ">
                            <label class="control-label "><strong>Loyalty Status<sup class="help text text-danger">*</sup>:</strong></label> <br/>
                            <div >
                              <label for="loyalty_status_enrolled">
                                <input id="loyalty_status_enrolled" type="radio" v-model="outlet.loyaltyStatus" value="ENROLLED" /> ENROLLED
                              </label>
                              <label for="loyalty_status_not_enrolled">
                                <input id="loyalty_status_not_enrolled" type="radio" v-model="outlet.loyaltyStatus" value="NOT_ENROLLED" /> NOT ENROLLED
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row" >
                        <div class="col-md-12">
                          <hr/>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-3">
                          <h4>Weekday</h4>
                          <div class="form-group ">
                            <label class="control-label "><strong>Opening time:</strong></label> <br/>
                            <div >
                              <date-picker style="width: 100%;" v-model="outlet.openingTime"  format="HH:mm" valueType="format" type="time"></date-picker>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <br/>
                          <div class="form-group ">
                            <label class="control-label "><strong>Closing time:</strong></label> <br/>
                            <div >
                              <date-picker style="width: 100%;" v-model="outlet.closingTime" format="HH:mm" valueType="format" type="time"></date-picker>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <h4>Weekends</h4>
                          <div class="form-group ">
                            <label class="control-label "><strong>Opening time:</strong></label> <br/>
                            <div >
                              <date-picker style="width: 100%;" v-model="outlet.openingTimeWeekend"  format="HH:mm" valueType="format" type="time"></date-picker>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <br/>
                          <div class="form-group ">
                            <label class="control-label "><strong>Closing time:</strong></label> <br/>
                            <div >
                              <date-picker style="width: 100%;" v-model="outlet.closingTimeWeekend" format="HH:mm" valueType="format" type="time"></date-picker>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row" >
                        <div class="col-md-12">
                          <hr/>
                        </div>
                      </div>

                      <div class="row" >
                        <div class="col-md-6">
                          <div class="form-group ">
                            <label class="control-label "><strong>Outlet type<sup class="help text text-danger">*</sup>:</strong></label> <br/>
                            <div >
                              <select required v-model="outlet.outletType" class="form-control" name="outlet type">
                                <option value=""> -- Select -- </option>
                                <option value="NORMAL" >Retail</option>
                                <option value="RESTAURANT" > Food</option>
                                <option value="ENTERTAINMENT" > Entertainment</option>
                              </select>
                              <!--<span v-show="errors.has('outlet type')" class="help text text-danger">{{ errors.first('outlet type') }}</span>-->
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group ">
                            <label class="control-label "><strong>Users:</strong></label> <br/>
                            <div>
                              <v-select
                                  label="name"
                                  v-model="selected_users"
                                  dir="ltr"
                                  multiple
                                  :options="users"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group ">
                            <label class="control-label "><strong>Netsuite ID:</strong></label> <br/>
                            <div >
                              <input class="form-control" type="text"  v-model="outlet.netsuiteId" />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group ">
                            <label class="control-label "><strong>Parking Voucher Authorisation:</strong></label> <br/>
                            <div >
                              <label for="parking_voucher_authorised">
                                <input id="parking_voucher_authorised" type="radio" v-model="outlet.parkingVoucherAuthorization" value="AUTHORIZED" /> Authorised
                              </label> &nbsp;&nbsp;
                              <label for="parking_voucher_not_authorised">
                                <input id="parking_voucher_not_authorised" type="radio" v-model="outlet.parkingVoucherAuthorization" value="UNAUTHORIZED" /> Unauthorised
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row" >
                        <div class="col-md-12">
                          <hr/>
                        </div>
                      </div>

                      <div class="row" >

                        <div class="col-md-6">
                          <div class="form-group ">
                            <label class="control-label "><strong>Logo<sup class="help text text-danger">*</sup>:</strong></label> <br/>
                            <div >
                              <input required class="form-control" type="file"  ref="logo" @change="handleLogoImageUpload" name="logo" />
                              <!--<span v-show="errors.has('merchant')" class="help text text-danger">{{ errors.first('logo') }}</span>-->
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group ">
                            <label class="control-label "><strong>Banner:</strong></label> <br/>
                            <div >
                              <input required class="form-control" type="file"  ref="banner" name="banner" @change="handleBannerImageUpload" />
                              <!--<span v-show="errors.has('banner')" class="help text text-danger">{{ errors.first('banner') }}</span>-->
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row" >
                        <div class="col-md-12">
                          <label class="control-label "><strong>Description<sup class="help text text-danger">*</sup>:</strong></label> <br/>
                          <textarea name="description" required class="form-control" rows="7" style="width: 100%;" v-model="outlet.description"></textarea>
                          <br/>
                          <!--<span v-show="errors.has('description')" class="help text text-danger">{{ errors.first('description') }}</span>-->
                        </div>
                      </div>


                    </div>
                  </app-timeline-item>
                  <app-timeline-item variant="secondary">
                    <div class="mb-4 bg-secondary p-1">
                      <h5 class="text-white">Partner Information </h5>
                    </div>

                    <div class="row" >
                      <div class="col-md-4">
                        <div class="form-group ">
                          <label class="control-label "><strong>Partner Type<sup class="help text text-danger">*</sup>:</strong></label> <br/>
                          <div >
                            <label for="loyalty_status_enrolled">
                              <input id="loyalty_status_enrolled" type="radio" v-model="outlet.partnerType" value="0" /> Internal
                            </label> &nbsp;&nbsp;
                            <label for="loyalty_status_not_enrolled">
                              <input id="loyalty_status_not_enrolled" type="radio" v-model="outlet.partnerType" value="1" /> External
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4" v-if="outlet.partnerType == 1">
                        <div class="form-group ">
                          <label class="control-label "><strong>Permissions<sup class="help text text-danger">*</sup>:</strong></label> <br/>
                          <div v-for="partnerPermission in partnerPermissions">
                            <label :for="partnerPermission">
                              &nbsp;&nbsp;<input :value="partnerPermission" type="checkbox" name="partner-permissions" v-model="outlet.partnerPermissions"   /> {{ partnerPermission }} &nbsp;&nbsp;

                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </app-timeline-item>
                </app-timeline>

                <div class="row" >
                  <div class="col-md-12">
                    <hr/>
                  </div>
                </div>


                <div class="row" >
                  <div class="col-md-12">
                    <button class="btn btn-danger float-right" style="color:#fff;" type="submit"> Submit </button>
                  </div>
                </div>



              </div>

            </form>
          </b-col>
        </b-row>

      </b-card>
    </b-container>

  </div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css'
  import Multiselect from 'vue-multiselect';
  import vSelect from 'vue-select'
  import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
  import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

export default {
  data() {
    return {
      selected_users:[],
      disabled:false,
      isLoading: false,
      fullPage: true,
      outlets:[],
      users:[],
      floor_levels:[-1,0,1,2,3,4,5,6,7,8,9,10],
      merchant_role:["Merchant","Cashier"],
      outlet_types:["NORMAL","RESTAURANT"],
      partnerPermissions:["Award Points", "Redeem Epurse", "Mobile App Access", "Merchant Portal"],
      outlet:{
        userId:"",
        id:0,
        name:"",
        phoneNumber:"",
        email:"",
        website:"",
        facebook:"",
        twitter:"",
        instagram:"",
        youtube:"",
        description:"",
        shopNo:"",
        openingTime:"",
        closingTime:"",
        logo:"",
        banner:"",
        category:"",
        floorLevel:"",
        outletType:"",
        openingTimeWeekend:"",
        closingTimeWeekend:"",
        terminalSerialNo:"",
        loyaltyStatus:"ENROLLED",
        netsuiteId:"",
        partnerType:0,
        partnerPermissions:[],
        parkingVoucherAuthorization:"UNAUTHORIZED"
      },
      categories:[],
      logo:"",
      banner:""
    };
  },
  components: {
    AppTimelineItem, AppTimeline,
    Loading,
    DatePicker,
    Multiselect,
    vSelect
  },
  mounted (){
    this.getCategoriesData();
    this.getUsersByRoleData(this.merchant_role)
  },
  methods: {
    ...mapActions(["getCategories","addOutlet","getOutlets","getUsersByRole"]),

    getUsersByRoleData(role_arr){

      let self = this;

      this.getUsersByRole({roles: role_arr})
        .then(function (users) {
          self.users = users;
        })
        .catch(function (error) {

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        });
    },

    handleLogoImageUpload(){
      this.logo = this.$refs.logo.files[0];
    },
    handleBannerImageUpload(){
      this.banner = this.$refs.banner.files[0];
    },
    getCategoriesData(){
      let self = this;
      this.getCategories()
        .then(function (categories) {
          self.categories = categories;
        })
        .catch(function (error) {

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },

    saveOutlet(){
      this.isLoading = true;

      let self = this;

      let formdata = new FormData();

      let userIds = this.selected_users.map(function (selectedUser) {
        return selectedUser.id;
      });

      formdata.append("logo", this.logo);
      formdata.append("banner", this.banner);
      formdata.append("name", this.outlet.name);
      formdata.append("phoneNumber", this.outlet.phoneNumber);
      formdata.append("email", this.outlet.email);
      formdata.append("website", this.outlet.website);
      formdata.append("facebook", this.outlet.facebook);
      formdata.append("twitter", this.outlet.twitter);
      formdata.append("instagram", this.outlet.instagram);
      formdata.append("youtube", this.outlet.youtube);
      formdata.append("description", this.outlet.description);
      formdata.append("shopNo", this.outlet.shopNo);
      formdata.append("netsuiteId", this.outlet.netsuiteId);
      formdata.append("users", userIds);
      formdata.append("openingTime", this.outlet.openingTime);
      formdata.append("closingTime", this.outlet.closingTime);
      formdata.append("openingTimeWeekend", this.outlet.openingTimeWeekend);
      formdata.append("closingTimeWeekend", this.outlet.closingTimeWeekend);
      formdata.append("category", this.outlet.category);
      formdata.append("floorLevel", this.outlet.floorLevel);
      formdata.append("loyaltyStatus", this.outlet.loyaltyStatus);
      formdata.append("outletType", this.outlet.outletType);
      formdata.append("terminalSerialNo", this.outlet.terminalSerialNo);
      formdata.append("partnerType", this.outlet.partnerType);
      formdata.append("partnerPermissions", this.outlet.partnerPermissions.toString());
      formdata.append("parkingVoucherAuthorization", this.outlet.parkingVoucherAuthorization);

      this.addOutlet(formdata).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Outlet added.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        this.$router.push("/apps/outlets");

        //self.getOutletData();

      })
        .catch(error => {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });

          /*if( typeof error.response.status != "undefined"){
             if(error.response.status == 401){
               location.replace("/app/sessions/signIn");
             }

          }
          else{




          }*/
        })
    },


    validateBeforeSubmitNewOutlet(){
      let self = this;
      self.saveOutlet();

    },
  },

};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
